import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import { useDeleteBuilder } from './hookBuilder'

export function useGetConfigurationData(refresh) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [configurationData, setConfigurationData] = useState([]);

    useEffect(() => {
        getConfigurationData();
    }, [refresh]);

    async function getConfigurationData() {

        await fetchDataAuthenticated("api/configurationData")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setConfigurationData(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get configuration data", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { configurationData, loading }
}


export function useSaveConfigurationData() {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveConfigurationData(configurationData) {

        let url = "api/configurationData";
        let method = "POST";
        if (configurationData.id != null) {
            url += `/${configurationData.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(configurationData))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved configuration data ${configurationData.id}`, configurationData)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to save configuration data", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveConfigurationData, loading }
}

export function useDeleteConfigurationData(configurationData) {
    const url = `api/configurationData/${configurationData.id}`;
    const location = !!configurationData.businessUnitId
        ? configurationData.businessUnitName
        : !!configurationData.siteId
            ? configurationData.siteName
            : null
    let successMessage = `Successfully deleted configuration data ${configurationData.configurationDataPropertyName}`;
    if (!!location) {
        successMessage = successMessage.concat(` for ${location}`);
    }
    const errorMessage = "Failed to delete configuration data";
    const { deleteFunction, loading } = useDeleteBuilder(url, successMessage, errorMessage);

    return { deleteFunction, loading };
}