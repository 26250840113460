import { PublicClientApplication } from '@azure/msal-browser';

const syncFetch = require('sync-fetch');
const clientConfig = syncFetch('api/config/clientConfig').json();

const config = {
    auth: {
        clientId: clientConfig.clientId,
        authority: clientConfig.authority,
        redirectUri: clientConfig.redirectUri
    }
};

export const client = new PublicClientApplication(config);

export const account = client.getAllAccounts()[0];

export const getAccessToken = async () => {

    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html#configuration
    let tokenRequest = {
        scopes: [clientConfig.tokenScope],
        account: client.getAllAccounts()[0]
    };

    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.publicclientapplication.html#acquiretokensilent
    let tokenResponse = await client.acquireTokenSilent(tokenRequest);

    return tokenResponse.accessToken;
}

export const signOut = () => {

    var account = client.getAllAccounts()[0];

    const logoutRequest = {
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        logoutHint: account.idTokenClaims.login_hint
    }

    client.logoutRedirect(logoutRequest);
};