import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

function DateRange({ dateFilter, setDateFilter }) {

    const { t } = useTranslation();

    const handleChange = useAsyncDebounce(e => {
        if (e.target.name === "filterFrom") {
            dateFilter.from = e.target.value;
        }
        if (e.target.name === "filterTo") {
            dateFilter.to = e.target.value;
        }

        setDateFilter(prevState => {
            return { ...prevState, ...dateFilter }
        });
    }, 500);

    return (
        <Row>
            <Col lg>
                <Form.Group as={Row} controlId="dateFrom">
                    <Form.Label column xs={2} sm={3}>{t('TableControls.Filter.DateFrom')}:</Form.Label>
                    <Col xs={10} sm={9}>
                        <Form.Control type="date" name="filterFrom" defaultValue={dateFilter.from} onChange={handleChange} required />
                    </Col>
                </Form.Group>
            </Col>
            <Col lg>
                <Form.Group as={Row} controlId="dateTo">
                    <Form.Label column xs={2} sm={3}>{t('TableControls.Filter.DateTo')}:</Form.Label>
                    <Col xs={10} sm={9}>
                        <Form.Control type="date" name="filterTo" defaultValue={dateFilter.to} onChange={handleChange} required />
                    </Col>
                </Form.Group>
            </Col>
        </Row>
            
    );
}

export default DateRange;