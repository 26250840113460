import React, { createContext } from "react";
import { usePrivileges } from "../hooks/usePrivileges";

const PrivilegesContext = createContext({
    contributor: undefined,
    supervisor: undefined,
    admin: undefined
});

export const PrivilegesProvider = ({ children }) => {
    const { contributor, supervisor, admin } = usePrivileges();
   
    return (
        <PrivilegesContext.Provider value={{ contributor, supervisor, admin } }>
            {children}
        </PrivilegesContext.Provider>
    )
}

export default PrivilegesContext;