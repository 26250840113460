import React, { useState } from 'react';
import { DialogInput, RequiredDialogInput, RequiredDialogSelect, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useGetBusinessUnits } from '../../../../hooks/useBusinessUnits';
import { useTranslation } from 'react-i18next';
import { useSaveProductionLine, useDeleteProductionLine } from '../../../../hooks/useProductionLines';

function ProductionLineEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const { t } = useTranslation();

    const [fieldErrors, setFieldErrors] = useState({});

    const { businessUnits } = useGetBusinessUnits();
    const { saveProductionLine } = useSaveProductionLine();
    const { deleteFunction } = useDeleteProductionLine(dialogData);

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Production Line - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveProductionLine} canDelete deleteFunction={deleteFunction} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formProductionLineName" title="Name" placeholder="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <RequiredDialogSelect controlId="formProductionLineBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {businessUnits.map((bu) => <option value={bu.id} key={bu.id}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <DialogInput controlId="formStatusDisplay" title="Display" placeholder="Display" name="display" value={dialogData.display} error={fieldErrors.display} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default ProductionLineEditDialog;