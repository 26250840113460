import React, { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useGetQIR } from '../../hooks/useQIRs';
import DeleteQIRButton from './DeleteQIRButton';
import QIRComponent from './QIRComponent';
import { useTranslation } from 'react-i18next';
import PrivilegesContext from '../../contexts/PrivilegesContext';

function QIRDetail() {

    const { t } = useTranslation();

    const { qirId } = useParams();
    const { qir, loading } = useGetQIR(qirId);
    const { admin, contributor } = useContext(PrivilegesContext);

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return qir ?
        <>
            <div className="d-flex justify-content-end me-3 mb-2">
                {contributor && 
                    <Link to={`/qir/${qirId}`} className="me-2">
                        <Button>{t("UpdateQIR.UpdateButton")}</Button>
                    </Link>
                }
                {admin &&
                    <DeleteQIRButton qirId={qirId} />
                }
            </div>
            <QIRComponent qir={qir} action="view" />
        </>
        : "";
}

export default QIRDetail;