import React, { createContext, useState, useEffect } from "react";
import { useUserBusinessUnits } from "../hooks/useUsers";

const BusinessUnitAccessContext = createContext({
    assignedBusinessUnits: []
});

export const BusinessUnitAccessProvider = ({ children }) => {
    const userBusinessUnits = useUserBusinessUnits();

    const [assignedBusinessUnits, setAssignedBusinessUnits] = useState();

    useEffect(() => {
        setAssignedBusinessUnits(userBusinessUnits);
    }, [userBusinessUnits]);

    return (
        <BusinessUnitAccessContext.Provider value={{ assignedBusinessUnits } }>
            {children}
        </BusinessUnitAccessContext.Provider>
    )
}

export default BusinessUnitAccessContext;