import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import QIRsTable from './QIRsTable';
import { useQIRReport } from '../../hooks/useQIRs';
import DateRange from '../DateRange';
import { CSVLink } from 'react-csv';
import BusinessUnitAccessContext from '../../contexts/BusinessUnitAccessContext';
import PrivilegesContext from '../../contexts/PrivilegesContext';

function QIRsList({ user, closed, tableName }) {

    const { t } = useTranslation();
    const { assignedBusinessUnits } = useContext(BusinessUnitAccessContext);
    const { contributor } = useContext(PrivilegesContext);
    const { getQIRReport, loading: loadingQIRReport } = useQIRReport();
    const [csvData, setCSVData] = useState();

    const [businessUnitNames, setBusinessUnitNames] = useState();

    const getFromDate = () => {
        // Today
        let fromDate = new Date();

        // Last day of the previous month
        fromDate.setDate(0);
        // First day of previous month after setting the date to the last day of the previous month
        fromDate.setDate(1);

        return fromDate.toISOString().split('T')[0];
    }

    const defaultDateRange = {
        from: getFromDate()
    };

    const [dateFilter, setDateFilter] = useState(defaultDateRange);

    const getDateString = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}${month}${day}`
    }

    useEffect(() => {
        if (assignedBusinessUnits) {
            setBusinessUnitNames(assignedBusinessUnits.map(bu => bu.name))
        }
    }, [assignedBusinessUnits])

    if (!assignedBusinessUnits) {
        return (
            <div className="d-flex justify-content-center">
                <p className="text-muted">{t('General.GettingPrivilegesMessage')}</p>
            </div>
        );
    }

    return (
        <Container className="mb-5">
            <Row>
                <Col>
                    <DateRange dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Col>
                <Col>
                    {contributor &&
                        <div className="d-flex justify-content-end mb-3">
                            <Link to="/qir/create" className="me-2">
                                <Button>{t('QIRList.CreateQIR')}</Button>
                            </Link>

                            <Button onClick={() => getQIRReport(businessUnitNames)} className="me-2">
                                {t('QIRList.DailyReport')}
                                {loadingQIRReport &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ms-2"
                                    />
                                }
                            </Button>

                            {csvData &&
                                <Button>
                                    <CSVLink className="text-white" style={{ textDecoration: 'none' }} filename={`QIR-Export-${getDateString()}.csv`} data={csvData} >
                                        {t('QIRList.ExportData')}
                                    </CSVLink>
                                </Button>
                            }
                        </div>
                    }
                </Col>
            </Row>

            {assignedBusinessUnits && dateFilter &&
                <Row>
                    <QIRsTable tableName={tableName} businessUnits={businessUnitNames} dateFrom={dateFilter.from} dateTo={dateFilter.to} user={user} closed={closed} setCSVData={setCSVData} />
                </Row>
            }
        </Container>
    )
}

export default QIRsList;