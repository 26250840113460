import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DraggableModalDialog } from '../DraggableModalDialog';

function CorporateDlg(props) {

    const { t } = useTranslation();

    CorporateDlg.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired
    }

    return (
        <Modal dialogAs={DraggableModalDialog} show={props.isOpen} onHide={props.toggleModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Footer.CorporateSeparatenessNotice')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Nothing in this material is intended to override the corporate separateness
                    of local entities. Working relationships discussed in this material do not
                    necessarily represent a reporting connection, but may reflect a functional
                    guidance, stewardship, or service relationship. Where shareholder
                    consideration of a local entity matter is contemplated by this material,
                    responsibility for action remains with the local entity.
                </p>
                <p>
                    Exxon Mobil Corporation has numerous affiliates, many with names that
                    include ExxonMobil, Exxon, Esso and Mobil. For convenience and simplicity
                    in this Site, those terms and terms like corporation, company, our, we and
                    its are sometimes used as abbreviated references to specific affiliates or
                    affiliate groups. Abbreviated references describing global or regional
                    operational organizations and global or regional business lines are also
                    sometimes used for convenience and simplicity. Similarly, ExxonMobil has
                    business relationships with thousands of customers, suppliers, governments,
                    and others. For convenience and simplicity, words like venture, joint
                    venture, partnership, co-venturer, and partner are used to indicate
                    business relationships involving common activities and interests, and those
                    words may not indicate precise legal relationships.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={props.toggleModal}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CorporateDlg;