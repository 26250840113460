import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import Sites from './Configuration/Sites';
import BusinessUnits from './Configuration/BusinessUnits';
import QIRStatus from './Configuration/QIRStatus';
import AffectedAreas from './Configuration/AffectedAreas';
import Categories from './Configuration/Categories';
import Grades from './Configuration/Grades';
import CommentReasonCodes from './Configuration/CommentReasonCodes';
import ConfigurationDataProperties from './Configuration/ConfigurationDataProperties';
import ConfigurationData from './Configuration/ConfigurationData';
import ProductionLines from './Configuration/ProductionLines';
import { useTranslation } from 'react-i18next';

function Configuration() {

    const { t } = useTranslation();

    return (
        <Container fluid>
            <h3 className="ms-2">Configuration</h3>
            <Tabs defaultActiveKey="sites" id="settings-tabs" className="tabs mt-3 mb-3" mountOnEnter={true} justify>
                <Tab eventKey="sites" title={t('Configuration.Tabs.Sites')} >
                    <Sites />
                </Tab>
                <Tab eventKey="businessUnits" title={t('Configuration.Tabs.BusinessUnits')} >
                    <BusinessUnits />
                </Tab>
                <Tab eventKey="configurationData" title={t('Configuration.Tabs.ConfigurationData')} >
                    <ConfigurationData />
                </Tab>
                <Tab eventKey="configurationDataProperties" title={t('Configuration.Tabs.ConfigurationDataProperties')} >
                    <ConfigurationDataProperties />
                </Tab>
                <Tab eventKey="qirStatus" title={t('Configuration.Tabs.QIRStatus')} >
                    <QIRStatus />
                </Tab>
                <Tab eventKey="productionLines" title={t('Configuration.Tabs.ProductionLines')} >
                    <ProductionLines />
                </Tab>
                <Tab eventKey="grades" title={t('Configuration.Tabs.Grades')} >
                    <Grades />
                </Tab>
                <Tab eventKey="affectedAreas" title={t('Configuration.Tabs.AffectedAreas')} >
                    <AffectedAreas />
                </Tab>
                <Tab eventKey="categories" title={t('Configuration.Tabs.Categories')} >
                    <Categories />
                </Tab>
                <Tab eventKey="commentReasonCodes" title={t('Configuration.Tabs.CommentReasonCodes')} >
                    <CommentReasonCodes />
                </Tab>
            </Tabs>
        </Container >
    );
}

export default Configuration;