import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { DraggableModalDialog } from '../../../Dialogs/DraggableModalDialog';
import CommentDialog from '../../../Dialogs/CommentDialog';
import PrivilegesContext from '../../../../contexts/PrivilegesContext';

function CommonConfigDialog({ children, title, dialogData, showDialog, closeDialog, save, fieldErrors, setFieldErrors, wideDialog = false, formId = "configForm", requireComment = true, canDelete, deleteFunction }) {
    const [initial] = useState({ requireComment });
    const [validated, setValidated] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const { admin } = useContext(PrivilegesContext);

    const handleClose = () => {
        setFieldErrors({});
        setValidated(false);
        closeDialog();
    }

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            if (initial.requireComment) {
                setShowCommentDialog(true);
            } else {
                saveDialogData();
            }
        }

        setValidated(true);
    }

    const handleDelete = () => {
        if (canDelete) {
            deleteFunction(dialogData)
                .then(_ => {
                    setFieldErrors({});
                    closeDialog();
                })
                .catch(error => {
                    setFieldErrors({
                        ...fieldErrors,
                        ...error.errors
                    });

                    for (const field in error.errors) {
                        let fieldDOM = document.querySelector("[name=" + field + "]");
                        fieldDOM.setCustomValidity(error.errors[field]);
                    }
                })
        }
    }

    const handleCommentSave = (comment) => {

        dialogData.comment = comment

        saveDialogData();
    }

    const saveDialogData = () => {
        save(dialogData)
            .then(data => {
                setFieldErrors({});
                closeDialog();
            })
            .catch(error => {
                setFieldErrors({
                    ...fieldErrors,
                    ...error.errors
                });

                for (const field in error.errors) {
                    let fieldDOM = document.querySelector("[name=" + field + "]");
                    fieldDOM.setCustomValidity(error.errors[field]);
                }
            });
    }

    return (
        <>
            {showDialog &&
                <Modal dialogAs={DraggableModalDialog} className={wideDialog ? "wide-dialog" : ""} show={showDialog} onHide={handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={handleSave} id={formId}>
                            {/*<fieldset disabled={!permissionToConfigure}>*/}
                            {/*    {children}*/}
                            {/*</fieldset>*/}
                            {children}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {canDelete &&
                            admin &&
                            !!dialogData.id &&
                            <Button variant="danger" onClick={handleDelete} className="me-auto">
                                Delete
                            </Button>    
                        }
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {/*<Button variant="primary" type="submit" form={formId} disabled={!permissionToConfigure}>*/}
                        <Button variant="primary" type="submit" form={formId}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {showCommentDialog &&
                <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleCommentSave} />
            }
        </>
    );
}

export default CommonConfigDialog;