import { useTranslation } from 'react-i18next';

export function useTranslationUtils () {
    const { t } = useTranslation();

    // Translate a token that translates to a string with substitution params and the params
    // are provided as a javascript object in 'args'
    const tp = function(token, args) {
        var translation = t(token);

        for (var property in args) {
            var search = `[[${property}]]`;

            translation = translation.replace(search, args[property]);
        }

        return translation;
    }

    // Translate a string that contains a key and potentially named parameters inline by
    // looking up a translation that potentially includes substitution params
    //
    // so input string,
    //      Warnings.Process.L3LabMismatch[[value=1.00,l3value=0.91,quality=Melt Index]]
    // becomes
    //      Warnings.Process.L3LabMismatch and {value=1.00||l3value=0.91||quality=Melt Index}
    // then we can cal
    //      tp()
    const ts = function (tokenAndArgs) {

        var argStart = tokenAndArgs.indexOf("[[");
        var argEnd = tokenAndArgs.indexOf("]]");

        if (argStart === -1) {
            // tokenAndArgs didn't actually include any args, so just translate the token
            return tokenAndArgs;
        }

        var token = tokenAndArgs.substr(0, argStart);

        if ((argStart + 2) === argEnd) {
            // empty substitution arguments, so we just have a token
            return t(token);
        }

        var argList = tokenAndArgs.substring(argStart + 2, argEnd);

        var allKeyValues = argList.split("||"); // So, || is the separator between substitution arguments in the message string

        // Build the args object by looking at the keys & values in the arguments list
        var args = {};

        for (var idx = 0; idx < allKeyValues.length; idx++) {

            var equalsPos = allKeyValues[idx].indexOf("="); // Not using split as the value itself my contain =, so we only care about the first equals

            var key = allKeyValues[idx].substr(0, equalsPos);
            var value = allKeyValues[idx].substring(equalsPos + 1);

            args[key] = value;
        }

        return tp(token, args);
    }

    return { tp, ts }
}
