import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function useUsers(businessUnitId) {

    const { setAlert } = useAlert();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (businessUnitId) {
            getUsers(businessUnitId);
        }
    }, [businessUnitId]);

    async function getUsers(businessUnitId) {

        let url = "api/user";

        if (businessUnitId) {
            url += `?businessUnitId=${businessUnitId}`
        }

        await fetchDataAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get users", error.message);
            })
    }

    return users;
}
export function useUserBusinessUnits() {

    const { setAlert } = useAlert();

    const [userBusinessUnits, setUserBusinessUnits] = useState();

    useEffect(() => {
        getUserBusinessUnits();
    }, []);

    async function getUserBusinessUnits() {

        await fetchDataAuthenticated(`api/user/businessUnits`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setUserBusinessUnits(data);
            })
            .catch(error => {
                setAlert("danger", "Failed to get business units for user", error.message);
            })
    }

    return userBusinessUnits;
}