import React, { useContext} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { Button, Modal, Row, Col, Card } from 'react-bootstrap';
import exxonLogo from '../../../images/cmn-exxonmobil-logo.gif';
import jemmacLogo from '../../../images/jemmac_logo.png';
import BusinessUnitAccessContext from '../../../contexts/BusinessUnitAccessContext';


function AboutDlg(props) {

    AboutDlg.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired,
        userName: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired
    }

    const { assignedBusinessUnits } = useContext(BusinessUnitAccessContext);

    const { t } = useTranslation();

    return (
        <Modal dialogAs={DraggableModalDialog} show={props.isOpen} onHide={props.toggleModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("Dialogs.About.Title")} 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t("Dialogs.About.Heading")} :</p>
                <Card>
                    <Card.Body>
                        <p>{t("Dialogs.About.Body1")}</p>
                        <p>{t("Dialogs.About.Body2")}</p>
                        <p>{t("Dialogs.About.Body3")}</p>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Application.Abbreviation")} {t("Dialogs.About.Version")}:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{props.version}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Dialogs.About.User")}:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{props.userName}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Dialogs.About.Privileges")}:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{props.privileges && props.privileges.toString()}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Dialogs.About.AssignedBusinessUnits")}:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{assignedBusinessUnits && assignedBusinessUnits.map(bu => bu.name).toString()}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <p className="text-center">{t("Dialogs.About.DevelopedBy")}</p>
                <Row>
                    <Col>
                        <img src={exxonLogo} alt="ExxonMobil logo" className="float-end me-3" />
                    </Col>
                    <Col>
                        <img src={jemmacLogo} alt="Jemmac logo" />
                    </Col>
                </Row>
                <p className="text-center">{props.copywrite}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={props.toggleModal}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AboutDlg;