import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function useGetBusinessUnits(refresh) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [businessUnits, setBusinessUnits] = useState([]);

    useEffect(() => {
        getBusinessUnits();
    }, [refresh]);

    async function getBusinessUnits() {

        await fetchDataAuthenticated("api/businessUnit")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setBusinessUnits(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get business units", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { businessUnits, loading }
}

export function useSaveBusinessUnit() {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveBusinessUnit(businessUnit) {

        let url = "api/businessUnit";
        let method = "POST";
        if (businessUnit.id != null) {
            url += `/${businessUnit.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(businessUnit))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved business unit ${businessUnit.id}`, businessUnit)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to save business unit", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveBusinessUnit, loading }
}

export function useGetLocalTimeForBusinessUnit(businessUnitId) {
    const { setAlert } = useAlert();

    const [localDate, setlocalDate] = useState();

    useEffect(() => {
        getLocalTimeForBusinessUnit(businessUnitId);
    }, [businessUnitId]);

    const getLocalTimeForBusinessUnit = async (businessUnitId) => {
        if (!businessUnitId) {
            return;
        }

        let url = `api/BusinessUnit/${businessUnitId}/localTime`
        await fetchDataAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                let date = new Date(data).toISOString().split(".")[0]
                setlocalDate(date);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Failed to get configuration data", error.message);
            });
    }

    return localDate
}