import React, { Suspense } from 'react';
import { Navigate, Route } from 'react-router';
import { Routes } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { client as publicClientApplication } from './utilities/AuthUtils';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';

import './custom.css';

import { AlertProvider } from './components/Alerts/AlertContext';
import NotificationAlert from './components/Alerts/NotificationAlert';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import QIRsList from './components/Pages/QIRsList';
import CreateQIR from './components/Pages/CreateQIR';
import QIRDetail from './components/Pages/QIRDetail';
import UpdateQIR from './components/Pages/UpdateQIR';
import { usePrivileges } from './hooks/usePrivileges';
import Configuration from './components/Pages/Configuration';
import { BusinessUnitAccessProvider } from './contexts/BusinessUnitAccessContext';
import { PrivilegesProvider } from './contexts/PrivilegesContext';

function LegacyApp(props) {

    const { t } = props;
    const account = publicClientApplication.getAllAccounts()[0];
    const { contributor, admin } = usePrivileges();

    return (
        <>
            <HelmetProvider>
                <AlertProvider>
                    <BusinessUnitAccessProvider>
                        <PrivilegesProvider>
                            <NotificationAlert />
                            <NavMenu title={t("Application.Abbreviation")} />
                            <Helmet>
                                <title>{t("Application.TabTitle")}</title>
                            </Helmet>
                            <Routes>
                                <Route path='/' element={<Navigate to='/qirs/open' />} />
                                <Route path='/qirs' exact element={<QIRsList tableName="qirs-table" />} />
                                <Route path='/qirs/my' element={<QIRsList user={account.username} tableName="my-qirs-table" />} />
                                <Route path='/qirs/open' element={<QIRsList closed={false} tableName="open-qirs-table" />} />
                                <Route path='/qirs/closed' element={<QIRsList closed={true} tableName="closed-qirs-table" />} />
                                <Route path='/qir/view/:qirId' element={<QIRDetail />} />
                                {contributor &&
                                    <>
                                        <Route path='/qir/create' element={<CreateQIR />} />
                                        <Route path='/qir/:qirId' element={<UpdateQIR />} />
                                    </>
                                }
                                {admin &&
                                    <Route path='/config' element={<Configuration />} />
                                }
                            </Routes>
                            <Footer />
                        </PrivilegesProvider>
                    </BusinessUnitAccessProvider>
                </AlertProvider>
            </HelmetProvider>
        </>
    );
}

const TranslatedApp = withTranslation()(LegacyApp);

export default function App() {
    return (
        <Suspense fallback="loading">
            <TranslatedApp />
        </Suspense>
    );
}